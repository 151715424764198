<template>
  <div class="privacy">
    <section style="min-height:250px" class="dark--gradient white--text">
      <v-container fluid>
        <v-row align="center" style="height: 300px">
          <v-col align="center">
            <h1 class="display-3">Privacy Policy</h1>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-container style="max-width:1200px">
      <v-card class="mt-n10">
        <v-card-text>
          <p class="c4">
            <span class="c8">Date Version: 2020-01-26</span>
          </p>
          <h2 class="c1" id="h.i9v6qq3i7ts9">
            <span class="c2">Who we are</span>
          </h2>
          <p class="c4">
            <span
              class="c0"
            >Our website address is: https://venturewallet.co (the &quot;Website&quot;).</span>
          </p>
          <h2 class="c1" id="h.c455z9ld1og1">
            <span class="c2">What personal data we collect and why we collect it</span>
          </h2>
          <h3 class="c5" id="h.qqfk92ibs6q3">
            <span class="c3">Comments</span>
          </h3>
          <p class="c4">
            <span
              class="c0"
            >When visitors leave comments on the Website we collect the data shown in the comments form, and also the visitor&rsquo;s IP address and browser user agent string to help spam detection.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.</span>
          </p>
          <h3 class="c5" id="h.k4a5jxuxrswt">
            <span class="c3">Media</span>
          </h3>
          <p class="c4">
            <span
              class="c0"
            >If you upload images to the Website, you should avoid uploading images with embedded location data (EXIF GPS) included. In the future, visitors to the website may be able to download and extract any location data from images on the Website.</span>
          </p>
          <h3 class="c5" id="h.v3q02ubcswjd">
            <span class="c3">Contact forms</span>
          </h3>
          <p class="c4">
            <span
              class="c0"
            >If you contact us via our contact forms or other methods, we collect your email address and other provided information in order to properly respond to your inquiries. Any information provided through contact forms is used solely for customer service purposes and will not be used for marketing or other purposes.</span>
          </p>
          <h3 class="c5" id="h.wkss2bkbwoam">
            <span class="c3">Cookies</span>
          </h3>
          <p class="c4">
            <span
              class="c0"
            >If you leave a comment on our Website you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >If you have an account and you log in to this Website, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select &quot;Remember Me&quot;, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</span>
          </p>
          <h3 class="c5" id="h.ftboe98qu58k">
            <span class="c3">Embedded content from other websites</span>
          </h3>
          <p class="c4">
            <span
              class="c0"
            >Articles on this Website may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</span>
          </p>
          <h3 class="c5" id="h.uhwvdpku3e7f">
            <span class="c3">Membership and Social Login</span>
          </h3>
          <p class="c4">
            <span
              class="c0"
            >We collect your public profile data only from your consent that you grant before initiating Social Login, from the social network used to login at our Website. This data includes your first name, last name, email address, link to your social media profile, unique identifier, link to social profile avatar. This data is used to create your user profile at our Website, and is the same data if manually provided via our registration form. You can revoke this consent at any time by sending us an email.</span>
          </p>
          <h3 class="c5" id="h.fde4q3kwoptj">
            <span class="c3">Personal Information</span>
          </h3>
          <p class="c4">
            <span
              class="c0"
            >In order for you to access certain services and to purchase products that we offer via the Website, we may require you to provide us with certain information that personally identifies you (&ldquo;Personal Information&rdquo;). We do not collect any Personal Information about you unless you voluntarily provide it to us.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >Personal Information includes the following categories of information: (1) Contact Data (such as your name, mailing address, and e-mail address); (2) Financial Data (such as your credit card information); 3) Demographic Data (such as your zip code, age, income, net worth); and 4) Investor Data (investment amounts, companies, transactions, and associated details). If you communicate with us by e-mail, post messages to any of our chat groups, comment fields, or forums, or otherwise complete online forms, surveys, or contest entries, any information provided in such communication may be collected as Personal Information.</span>
          </p>
          <h3 class="c5" id="h.6rbo8wrjbs8p">
            <span class="c3">Traffic Data</span>
          </h3>
          <p class="c4">
            <span
              class="c0"
            >We track and collect the following categories of information when you visit our Website: (1) IP addresses; (2) domain servers; (3) types of computers accessing the Website; and (4) types of web browsers used to access the Website (collectively &ldquo;Traffic Data&rdquo;). Traffic Data is anonymous information that does not personally identify you but is helpful for marketing purposes or for improving your experience on the Website. We also use &ldquo;cookies&rdquo; to customize content specific to your interests, to ensure that you do not see the same advertisement repeatedly, and to store your password so you do not have to re-enter it each time you visit the Website.</span>
          </p>
          <h2 class="c1" id="h.hqa7r592jkm9">
            <span class="c2">Who we share your data with</span>
          </h2>
          <p class="c4">
            <span
              class="c0"
            >We will not share personal data with anyone outside of Crowdwise, LLC d/b/a VentureWallet without prior written consent, other than the forms of sharing that are outlined on this page.</span>
          </p>
          <h2 class="c1" id="h.d0x5wr4q31lw">
            <span class="c2">How long we retain your data</span>
          </h2>
          <p class="c4">
            <span
              class="c0"
            >If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >For users that register on our Website, we also store the personal information they provide in their user profile and any other investment information that they provide, input, submit, or upload while using the Website. All users can see, edit, or delete their personal information at any time. Website administrators can also see and edit that information.</span>
          </p>
          <h2 class="c1" id="h.lnjb1ndulvrb">
            <span class="c2">What rights you have over your data</span>
          </h2>
          <p class="c4">
            <span
              class="c0"
            >If you have an account on this Website, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</span>
          </p>
          <h2 class="c1" id="h.wxqamyjgmg2g">
            <span class="c2">Where we send your data</span>
          </h2>
          <p class="c4">
            <span
              class="c0"
            >Visitor comments may be checked through an automated spam detection service.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >We may share aggregated and anonymous Demographic Data with third parties.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >We may also share Contact Data with our business partners who assist us by performing core services (such as hosting, billing, fulfillment, or data storage and security) related to our operation of the Website.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >When you provide us with your Financial Data (e.g. credit card or debit card details), it will immediately be transferred to and stored with our third party payment-processing partner (i.e. Paypal or Stripe). We will not retain any of your Financial Data in our database.</span>
          </p>
          <p class="c4">
            <span
              class="c0"
            >You acknowledge that once your Personal Information is shared with or transferred to third parties as provided herein, the use of your Personal Information will then be governed by the privacy policies of those third parties and is not subject to our control. If contacted by those third parties, you should directly inquire them about the use of your Personal Information by them.</span>
          </p>
          <h2 class="c1" id="h.fbb8ljgenknj">
            <span class="c2">Children Under the Age of 13</span>
          </h2>
          <p class="c4">
            <span
              class="c0"
            >In compliance with Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;), this Website is not directed at children under the age of 13 and we do not intend to collect any personal identifiable information, such as name, home address, e-mail address, telephone number, or social security number, from children under the age of 13. If you are under the age of 13, please do not provide us with your personal identifiable information. If you have any reason to believe that a child under the age of 13 has provided us with his or her personal identifiable information, please contact us immediately for removal of that information from our databases.</span>
          </p>
          <h2 class="c1" id="h.89jmnf6f4gjc">
            <span class="c2">Updates and Changes to Privacy Policy</span>
          </h2>
          <p class="c4">
            <span
              class="c0"
            >We reserve the right, at any time and without notice, to add to, change, update, or modify this Privacy Policy, simply by posting such change, update, or modification on the Website and without any other notice to you. Any such change, update, or modification will be effective immediately upon posting on the Website.</span>
          </p>
          <h2 class="c1" id="h.87c6063mr1sk">
            <span class="c2">Contact Information</span>
          </h2>
          <p class="c4">
            <span
              class="c0"
            >If you have any questions or concerns regarding your data or this privacy policy, contact us.</span>
          </p>
          <p class="c4 c6">
            <span class="c0"></span>
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

  <style scoped type="text/css">
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c9 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c3 {
  color: #424242;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: "Arial";
  font-style: normal;
}
.c0 {
  color: #424242;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c2 {
  color: #424242;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 17pt;
  font-family: "Arial";
  font-style: normal;
}
.c5 {
  padding-top: 14pt;
  padding-bottom: 4pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c1 {
  padding-top: 18pt;
  padding-bottom: 4pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c10 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c7 {
  color: inherit;
  text-decoration: inherit;
}
.c8 {
  font-style: italic;
}
.c6 {
  height: 11pt;
}
.title {
  padding-top: 10pt;
  color: #424242;
  font-size: 52pt;
  padding-bottom: 0pt;
  font-family: "Oswald";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 0pt;
  font-family: "Covered By Your Grace";
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: center;
}
li {
  color: #424242;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #424242;
  font-size: 11pt;
  font-family: "Arial";
}
h2 {
  padding-top: 0pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 0pt;
  font-family: "Oswald";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
h3 {
  padding-top: 0pt;
  color: #e31c60;
  font-size: 10pt;
  padding-bottom: 0pt;
  font-family: "Arial";
  line-height: 1.3;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 8pt;
  -webkit-text-decoration-skip: none;
  color: #666666;
  text-decoration: underline;
  font-size: 11pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  page-break-after: avoid;
  text-decoration-skip-ink: none;
  font-family: "Trebuchet MS";
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 8pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Trebuchet MS";
  line-height: 1.3;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 8pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Trebuchet MS";
  line-height: 1.3;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>